import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {
  useShowInstructions,
  useSiteVersionId,
} from '@backstage-components/base';
import {AccessCodeInstructionSchema} from '@backstage-components/access-code';
import {PublicAccessCodeInstructionSchema} from '@backstage-components/public-access-code';
import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import {Attendee, ContainerMachine} from './attendee-container-machine';
import {readAccessToken} from './attendee-session-token';
import {Type} from '@sinclair/typebox';

const InstructionSchema = Type.Union([
  ...AccessCodeInstructionSchema.anyOf,
  ...PublicAccessCodeInstructionSchema.anyOf,
]);

interface AttendeeContextBaseValue {
  fetchAttendee: () => void;
  verifyAccessCode: (accessCode: string) => void;
}

interface AttendeeContextAttendeeValue extends AttendeeContextBaseValue {
  attendeeId: string;
  attendeeName: string;
  attendeeEmail: string | null;
  token?: string;
  attendeeTags: string[];
  isPublic?: boolean;
  sessionToken?: string;
}

type AttendeeContextValue =
  | AttendeeContextBaseValue
  | AttendeeContextAttendeeValue;

interface AttendeeProviderProps<ApolloCache = NormalizedCacheObject> {
  client: ApolloClient<ApolloCache>;
  attendeeId?: string;
  showId: string;
}

/**
 * @private exported for tests
 */
export const AttendeeContainer = createContext<
  AttendeeContextValue | undefined
>(undefined);
AttendeeContainer.displayName = 'AttendeeContainer';

/**
 * Context `Provider` to create and hold an attendee record.
 */
export const AttendeeProvider: FC<AttendeeProviderProps> = (props) => {
  const {client, showId} = props;
  const siteVersionId = useSiteVersionId();
  const [state, dispatch] = useMachine(ContainerMachine, {
    context: {client, showId},
  });
  const attendee: Attendee | undefined = useMemo(() => {
    if (state.matches('success')) {
      return {
        id: state.context.attendee.id,
        name: state.context.attendee.name,
        email: state.context.attendee.email,
        chatTokens: state.context.attendee.chatTokens.filter(
          (token) => token.token.length > 0
        ),
        attendeeTags: state.context.attendeeTags || [],
        isPublic: state.context.attendee.isPublic,
      };
    } else {
      return undefined;
    }
  }, [state]);
  const fetchAttendee = useCallback((): void => {
    if (state.matches('idle')) {
      dispatch({type: 'FETCH', meta: {}});
    } else {
      throw new Error(`Can not verify, machine is ${state.value}`);
    }
  }, [state, dispatch]);
  const verifyAccessCode = useCallback(
    (accessCode: string): void => {
      if (state.matches('idle')) {
        dispatch({type: 'VERIFY', meta: {accessCode, showId}});
      } else {
        throw new Error(`Can not verify, machine is ${state.value}`);
      }
    },
    [state, dispatch, showId]
  );
  // Listen for broadcasts from components of type `AccessCode` and `PublicAccessCode`.
  // when a verify request happens, forward it to the state machine.
  const {observable, broadcast} = useShowInstructions(InstructionSchema);
  useSubscription(observable, {
    next: (instruction) => {
      if (
        instruction.type === 'AccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        broadcast({
          type: 'AccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'AccessCode:verify') {
        dispatch({
          type: 'VERIFY',
          meta: {
            about: instruction.meta.about,
            accessCode: instruction.meta.accessCode,
            showId,
            siteVersionId,
          },
        });
      } else if (
        instruction.type === 'PublicAccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        broadcast({
          type: 'PublicAccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'PublicAccessCode:verify') {
        dispatch({
          type: 'VERIFY_PUBLIC',
          meta: {
            about: instruction.meta.about,
            moduleId: instruction.meta.moduleId,
            passCode: instruction.meta.passCode,
            showId,
            siteVersionId,
            name: instruction.meta.name,
          },
        });
      }
    },
  });
  // When the state machine transitions, if `about` is set in context then
  // broadcast an instruction indicating success or failure.
  useEffect(() => {
    if (state.matches('failure') && state.context.about) {
      const meta = {about: state.context.about, reason: state.context.reason};
      broadcast({type: 'AccessCode:failure', meta});
      broadcast({type: 'PublicAccessCode:failure', meta});
    } else if (state.matches('success') && state.context.about) {
      const {attendee} = state.context;
      const myType = attendee.isPublic
        ? 'PublicAccessCode:success'
        : 'AccessCode:success';
      broadcast({
        type: myType,
        meta: {
          about: state.context.about,
          attendee: {
            chatTokens: attendee.chatTokens,
            email: attendee.email,
            id: attendee.id,
            name: attendee.name,
            tags: attendee.attendeeTags,
          },
        },
      });
    }
  }, [broadcast, state]);

  const value: AttendeeContextValue = useMemo(() => {
    const baseContextValue: AttendeeContextBaseValue = {
      fetchAttendee,
      verifyAccessCode,
    };
    if (attendee) {
      return Object.assign(
        {
          attendeeId: attendee.id,
          attendeeName: attendee.name,
          attendeeEmail: attendee.email,
          token:
            attendee.chatTokens.length > 0
              ? attendee.chatTokens[0].token
              : undefined,
          attendeeTags: attendee.attendeeTags ?? [],
          sessionToken: readAccessToken(showId),
        },
        baseContextValue
      );
    } else {
      return baseContextValue;
    }
  }, [attendee, fetchAttendee, showId, verifyAccessCode]);
  return <AttendeeContainer.Provider value={value} children={props.children} />;
};

/**
 * Gives access to the already verified attendee if it exists, or requests
 * verification with a given `accessCode`. When called without `accessCode`
 * only provides an existing attendee.
 * @param accessCode of an attendee to verify.
 * @returns the attendee record if it exists, `null` if it does not.
 */
export const useVerifiedAttendee = (accessCode?: string): Attendee | null => {
  const context = useContext(AttendeeContainer);
  if (context === undefined) {
    throw new Error(noContextError('useVerifiedAttendee'));
  }
  if ('attendeeId' in context) {
    return attendeeFromContext(context);
  } else if (typeof accessCode === 'string') {
    context.verifyAccessCode(accessCode);
    return null;
  } else {
    // no current attendee and no `accessCode` provided
    return null;
  }
};

/**
 * a error report helper for providing the client with feedback about a nullish context
 * @param subject - the name of the subject, or lack thereof, that caused the error
 * @returns - an error message
 * @private exported for testing
 */
export const noContextError = (subject: string): string =>
  `${subject} must be used within an AttendeeProvider`;

/**
 * Gives access to the currently authenticated attendee if it exists.
 * @returns the attendee record if it exists, `null` if it does not.
 */
export const useAttendee = (): Attendee | null => {
  const context = useContext(AttendeeContainer);
  if (context === undefined) {
    throw new Error(noContextError('useAttendee'));
  }
  if ('attendeeId' in context) {
    return attendeeFromContext(context);
  } else {
    // No current attendee and no `attendeeId` passed
    return null;
  }
};

function attendeeFromContext(context: AttendeeContextValue): Attendee | null {
  if ('attendeeId' in context) {
    return {
      id: context.attendeeId,
      name: context.attendeeName,
      email: context.attendeeEmail ?? null,
      chatTokens:
        typeof context.token === 'string' ? [{token: context.token}] : [],
      attendeeTags: context.attendeeTags,
      isPublic: context.isPublic ?? false,
      sessionToken: context.sessionToken,
    };
  } else {
    return null;
  }
}
